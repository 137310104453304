import React from "react"
import Layout from "../components/Layout"

export default function Home() {
  return (
    <Layout>
      <main className="page">
        <header className="hero">
          <div className="hero-container">
            <div className="hero-text">
              <h1>The future of remote work is Async</h1>
              <p style={{ color: "white" }}>
                FindAsync is an upcoming online niche job platform that connects
                jobseekers and asynchronous remote companies. Our goal is help
                workers discover their dream job no matter their timezone or
                schedule, and async companies find the right talent. Using our
                async-work-related content, we plan to support, educate, and
                assist jobseekers, companies, as well as the public in the
                adoption of asynchronous remote work culture.
              </p>
              <p style={{ color: "white" }}>
                We will be launching our website soon. Be sure to check out our
                site in the future to see any changes.
              </p>
              <p style={{ color: "white" }}>
                To get in touch with us, please send an email to
                contact@findasync.com. We look forward to hearing from you.
              </p>
            </div>
          </div>
        </header>
      </main>
    </Layout>
  )
}
